import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navigation.css';


function Navbar() {
  return (
    <nav>
      <ul className="nav-links">
        <li>
          <NavLink exact to="/">Projects</NavLink>
        </li>
        <li>
          <NavLink exact to="/experience">Experience</NavLink>
        </li>
      </ul>
    </nav>
  )
}

export default Navbar
