import React from "react";

import gerbrock from "../../images/gerbrock.png";
import redantpdx from "../../images/redantpdx.png";
import movieDB from "../../images/movieDb.png";
import scratch from "../../images/scratch_serverless.png";
import natours from "../../images/natours.png";

import gerbrock_small from "../../images/gerbrock_small.png";
import redantpdx_small from "../../images/redantpdx_small.png";
import movieDB_small from "../../images/movieDb.png";
import scratch_small from "../../images/scratch_serverless_small.png";
import natours_small from "../../images/natours_small.png";

import { GitHubIcon } from "../../icons";

export default function ProjectList({ projects }) {
  const photos = [gerbrock, redantpdx, movieDB, scratch, natours];
  const smallPhotos = [
    gerbrock_small,
    redantpdx_small,
    movieDB_small,
    scratch_small,
    natours_small,
  ];
  return (
    <div className="row">
      {projects &&
        projects.map((project, i) => {
          const formattedTech = project.tech.map((item) => (
            <span key={item} className="item-tech-pills">
              {item}
            </span>
          ));
          return (
            <div
              className="col-md-6 d-flex align-items-stretch mb-4"
              key={project.id}
            >
              <div className="card">
                <img
                  src={photos[i]}
                  srcSet={`${smallPhotos[i]} 500w, ${photos[i]}, 750w`}
                  alt={project.name}
                  className="card-img-top"
                />
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title">{project.name}</h5>
                  <p className="card-text">{project.description}</p>
                  <h6 className="tech-container">{formattedTech}</h6>
                  <p className="text-center mt-auto">
                    <a href={project.url} className="btn btn-outline-info">
                      Go to Site
                    </a>
                    {project.gitHub && (
                      <a href={project.gitHub} className="icon-container">
                        <GitHubIcon />
                      </a>
                    )}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}
