import React from 'react'

function FourOhFour() {
  return (
    <div>
      <h1>Error 404 not found!</h1>
      <p>This isn't the page you're looking for...</p>
    </div>
  )
}

export default FourOhFour
