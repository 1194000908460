import React from "react";
import { LinkedInIcon, GitHubIcon } from "../icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

function About() {
  return (
    <>
      <div className="text-center about-section pt-5">
        <h1 className="mb-2">Jason T. Brown</h1>
        <h2 className="mb-4">Front End Developer</h2>

        <div className="about--icon-container mb-4">
          <a
            href="https://github.com/Evan-Alexander/"
            title="View my Github repos."
          >
            <GitHubIcon />
          </a>
          <a
            href="https://www.linkedin.com/in/jasontbrown99/"
            title="View my LinkedIn profile."
          >
            <LinkedInIcon />
          </a>
          <a href="mailto:jasontbrown99@gmail.com" title="Send me an email">
            <FontAwesomeIcon icon={faEnvelope} style={{ color: "#FFF" }} />
          </a>
        </div>

        <p className="">
          Thanks for visiting! I’m a Front End Developer with over 6 years of experience. 
        </p>
      </div>
    </>
  );
}

export default About;
