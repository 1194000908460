import React from 'react';

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLaptopCode, faGraduationCap, faLink, faBusinessTime } from '@fortawesome/free-solid-svg-icons'
import './TimeLine.css';
import resume from '../resume.json';

const renderTimeline = resume && resume.map((item, i) => {
  let timelineIcon;
      if (item.company !== "" && item.notProgramming === undefined) {
        timelineIcon =  <FontAwesomeIcon icon={faLaptopCode} />;
      } else if (item.school !== "") {
        timelineIcon =  <FontAwesomeIcon icon={faGraduationCap} />;
      } else {
        timelineIcon =  <FontAwesomeIcon icon={faBusinessTime} />;
      }
  const renderItemTech = item.tech.map((item, i) => (
    <span key={i} className="item-tech-pills">{item}</span>
  ));
  return (
        <VerticalTimelineElement
          key={i}
          className={`vertical-timeline-element--${item.company === "" ? 'education' : 'work'}`}
          contentStyle={{ background: 'rgb(0, 126, 167)', color: '#fff' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(0, 126, 167)' }}
          date={item.jobDate}
          iconStyle={item.company !== "" ? { background: 'rgb(128, 206, 215)'} : { background: 'rgb(255, 131, 0)'}}
          icon ={timelineIcon}
        >
          <h3 className="vertical-timeline-element-title">{item.jobDescription}</h3>
          <h5 className="vertical-timeline-element-subtitle">
            <span><FontAwesomeIcon icon={faLink} /></span>
            <a rel="noopener noreferrer" target="_blank" href={item.url}>
              {item.company === "" ? item.school : item.company}
            </a>
          </h5>
          <p>
            {item.description}
          </p>
          <p className="tech-container">
            {renderItemTech}
          </p>
        </VerticalTimelineElement>
  )
})
function TimeLine() {
  return (
      <VerticalTimeline>
        {renderTimeline}
      </VerticalTimeline>
  )
}

export default TimeLine;
