import React from 'react'
import Navbar from '../components/navigation/Navbar';
import jason from '../images/me.jpeg';

function Header() {
  return (
    <>
      <div className="triangle-container">
        <Navbar />
        <svg viewBox='0 0 300 100' preserveAspectRatio='none'>
        <defs>
          <linearGradient id="myGradient" gradientTransform="rotate(90)">
            <stop offset="5%"  stopColor="#ff8300" />
            <stop offset="95%" stopColor="#ff2700" />
          </linearGradient>
        </defs>
          <polygon className="polygon" points='0,70 300,20 300, 80 0,100z' fill="url('#myGradient')"/>
        </svg>
        <img className="portrait" src={jason} alt="Jason T. Brown Frontend Developer"/>
      </div>
    </>
  )
}

export default Header
