import React from "react";
import TimeLine from "../components/TimeLine";

function Experience() {
  return (
    <div className="container">
      <h3 className="mt-4 mb-4 text-center">
        Work experience
      </h3>
      <TimeLine />
    </div>
  );
}

export default Experience;
