import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Experience from './containers/Experience';
import FourOhFour from './containers/FourOhFour';
import About from "./containers/About";
import Header from './components/Header';
import ProjectList from "./components/projects/ProjectList";
import projects from "./projects.json";
function App() {

  return (
    <BrowserRouter>
      <div className="container">
      <About />
      <Header />
      <Switch>
        <Route exact path='/' render={() => <ProjectList projects={projects} />} />
        <Route exact path='/experience' component={Experience} />
        <Route component={FourOhFour} />
      </Switch>                                                          
      </div>
    </BrowserRouter>
  );
}

export default App;
